import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';

export const UsersContext = createContext();

export function UsersContextProvider({ children }) {
   const { user } = useContext(CommonContext)
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [viewData, setViewData] = useState('');
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setViewData('')
    };

    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };
    const onLoadApi = () => {
        let postReq = {};
        if (user?.user_type !== "Merchant") {
            postReq = { admin_id: user?.id}
        }
        setFirstLoading(false);
        commonApi.post('/users/list', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllUsers(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        onLoadApi();
    }, []);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        isEdit,
        viewData,
        onLoadApi,
        allUsers,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        isLoading,
        firstLoading,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setIsLoading,
        setFirstLoading,
        setOpenAlert,
        setAlertMesaage,
        setSeverity,
        setAllUsers,
        setViewData,
        setIsEdit,
    };

    // Render the provider with children
    return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
}
