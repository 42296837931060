import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

export const ShipmentsContext = createContext();

export function ShipmentsContextProvider({ children }) {
    const { user, globalSearch, isSearch } = useContext(CommonContext);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [uploadDataRes, setUploadDataRes] = useState();
    const [selected, setSelected] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [canceldialog, setCancelDialog] = useState(false);
    const [cancelRemarks, setCancelRemarks] = useState('');
    const [expandedTracking, setExpandedTracking] = useState(false);
    const [isReset, setIsReset] = useState(true);
    const [fStartDate, setFStartDate] = useState(null);
    const [fEndDate, setFEndDate] = useState(null);
    const [trackingInfo, setTrackingInfo] = useState([]);
    const [awbLr, setAwbLr] = useState({});
    const [osId, setOsId] = useState(24);
    const [filterType, setFilterType] = useState('awb no.');
    const [searchValue, setSearchValue] = useState('');
    const [selectedCourierIds, setSelectedCourierIds] = useState([]);
    const [selectedMerchantIds, setSelectedMerchantIds] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
    const [fDateRange, setFDateRange] = useState(['', '']);
    const [filterInput, setfilterInput] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);


    useEffect(() => {
        if (isSearch) {
            onLoadApi();
        }
    }, [isSearch])
    useEffect(() => {
        const fInput = {
            searchBy: filterType === 'LR No.' ? 'lrnum' : filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, ''),
            searchValue: searchValue ?? '', courier_id: selectedCourierIds ?? [],
            merchant_id: user?.user_type !== 'Merchant' ? selectedMerchantIds : [user?.id],
            start_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
            order_status_id: osId !== 99 ? [osId] : selectedOrderStatus
        }

        setfilterInput(fInput)
    }, [searchValue, filterType, fDateRange, selectedCourierIds, selectedMerchantIds]);

    const onLoadApi = (dateRange, r, prPage, pageNo) => {
        // setPage(0);
        // setRowsPerPage(20);
        setAllData([]);
        setAllOrders([]);
        // let postReq = { order_status_id: osId !== 99 ? osId : selectedOrderStatus, start_date: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : '', end_date: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : '' };
        let postReq = {
            searchBy: filterType === 'LR No.' ? 'lrnum' : filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, ''),
            searchValue: isReset ? '' : searchValue,
            courier_id: isReset ? [] : selectedCourierIds ?? [],
            merchant_id: user?.user_type !== 'Merchant' ? isReset ? [] : selectedMerchantIds : [user?.id],
            start_date: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
            order_status_id: osId !== 99 ? [osId] : isReset ? [] : selectedOrderStatus
        };
        if (globalSearch) {
            setSearchValue(globalSearch);
            setFilterType('awb no.');
            setOsId(99);
            postReq.start_date = ''
            postReq.end_date = ''
            postReq.searchValue = globalSearch
            postReq.searchBy = 'awb_no'
            setFStartDate(null);
            setFEndDate(null);
            setFDateRange(['', '']);
        }
        setfilterInput(postReq);
        // let postReq = filterInput;
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        }
        setFirstLoading(false);
        setIsReset(false);
        commonApi.post(`/orders/list?page=${isReset ? 1 : page + 1}&per_page=${isReset ? 20 : rowsPerPage}`, postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllOrders(response?.data?.data);
                    setAllData(response?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onCancelOrder = () => {
        setIsLoading(true);
        commonApi.post('/orders/cancel-orders', { "order_ids": selected.join(', '), "remarks": cancelRemarks })
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('success');
                    setCancelRemarks('');
                    setSelected([]);
                    setCancelDialog(false);
                    onLoadApi();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setOpenAlert(true);
                setAlertMesaage(error.response?.data?.msg);
                setSeverity('error')
                console.error('Error fetching data:', error);
            });
    };
    const recheckStatus = () => {
        setIsLoading(true);
        commonApi.post('/cron/tracking', { "order_ids": selected.join(', ') })
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('success');
                    setCancelRemarks('');
                    setSelected([]);
                    setCancelDialog(false);
                    onLoadApi();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setOpenAlert(true);
                setAlertMesaage(error.response?.data?.msg);
                setSeverity('error')
                console.error('Error fetching data:', error);
            });
    };
    const onLabelGen = () => {
        setIsLoading(true);
        commonApi.post('/orders/generate-address-labels', { "order_ids": selected.join(', ') })
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    setAlertMesaage('Address Label Generated');
                    setSeverity('success');
                    setSelected([]);
                    onLoadApi();
                    setTimeout(() => {
                        navigate('/download');
                    }, 3000);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };
    const onShip = () => {
        setIsLoading(true);
        commonApi.post('/orders/ship', { "order_ids": selected.join(', ') })
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('success');
                    setSelected([]);
                    onLoadApi();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };
    const onExportOrder = () => {
        if (user?.user_type !== "Merchant") {
            filterInput.admin_id = user?.id
        }
        setIsLoading(true);
        commonApi.post('/orders/export', filterInput)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };

    const handleCancelModal = () => {
        setCancelDialog(false);
        setCancelRemarks('');
        setSelected([]);
    };
    const handleAlertModalModal = () => {
        setAlertModal(true);
    };

    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };
    const handleCloseAlertModalModal = () => {
        setAlertModal(false);
        onLoadApi();
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const openStatusModal = () => {
        setStatusModal(true);
    };
    const closeStatusModal = () => {
        setStatusModal(false);
    };
    useEffect(() => {
        if (isReset) {
            setRowsPerPage(20);
            setPage(0);
            if (!globalSearch && osId !== 1) {
                const today = moment();
                const defaultStartDate = today.clone().startOf('month');
                const defaultEndDate = today.clone().endOf('month');
                const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];

                setFEndDate(defaultEndDate.format('YYYY-MM-DD'));
                setFStartDate(defaultStartDate.format('YYYY-MM-DD'));
                setFDateRange(defaultDateRange);
                onLoadApi(defaultDateRange);
            } else if (osId === 1) {
                const defaultStartDate = moment();
                const defaultEndDate = moment();
                const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
                setFEndDate(moment(defaultEndDate.toDate()).format('YYYY-MM-DD'));
                setFStartDate(moment(defaultStartDate.toDate()).format('YYYY-MM-DD'));
                setFDateRange(defaultDateRange);
                onLoadApi(defaultDateRange);
            } else {
                onLoadApi();
            }
        }
        // const [fDateRange, setFDateRange] = useState(defaultDateRange);

    }, [osId, isReset]);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        isReset,
        setIsReset,
        selectedOrderStatus,
        setSelectedOrderStatus,
        recheckStatus,
        onShip,
        statusModal,
        setStatusModal,
        page,
        rowsPerPage,
        filterType,
        searchValue,
        selectedCourierIds,
        selectedMerchantIds,
        fDateRange,
        onExportOrder,
        filterInput,
        fStartDate,
        fEndDate,
        awbLr,
        osId,
        trackingInfo,
        expandedTracking,
        cancelRemarks,
        handleCancelModal,
        canceldialog,
        onCancelOrder,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        onLabelGen,
        selected,
        uploadDataRes,
        alertModal,
        handleCloseAlertModalModal,
        handleAlertModalModal,
        onLoadApi,
        isLoading,
        firstLoading,
        allOrders,
        allData,
        handleAddModal,
        handleCloseModal,
        closeStatusModal,
        openStatusModal,
        setAddModal,
        addModal,
        setAllData,
        setAllOrders,
        setIsLoading,
        setFirstLoading,
        setAlertModal,
        setUploadDataRes,
        setSelected,
        setOpenAlert,
        setAlertMesaage,
        setSeverity,
        setCancelDialog,
        setCancelRemarks,
        setExpandedTracking,
        setTrackingInfo,
        setOsId,
        setAwbLr,
        setFEndDate,
        setFStartDate,
        setfilterInput,
        setFDateRange,
        setSelectedMerchantIds,
        setSelectedCourierIds,
        setSearchValue,
        setFilterType,
        setPage,
        setRowsPerPage,
    };

    // Render the provider with children
    return <ShipmentsContext.Provider value={contextValue}>{children}</ShipmentsContext.Provider>;
}
