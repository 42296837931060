import React, { createContext, useState } from 'react';

export const RTOContext = createContext();

export function RTOContextProvider({ children }) {
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allRTO, setAllRTO] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };



    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        isLoading,
        firstLoading,
        allRTO,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllRTO,
        setFirstLoading,
        setIsLoading
    };

    // Render the provider with children
    return <RTOContext.Provider value={contextValue}>{children}</RTOContext.Provider>;
}
