import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const Pageloader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress sx={{height: '100px', width: '100px'}} color="primary" />
    </div>
  );
};

export default Pageloader;
