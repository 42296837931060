// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';

const NotFound = () => {

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Typography
                sx={{
                    fontSize: '4rem',
                    marginBottom: '1rem',
                }}
                variant="h1" color="primary">
                404
            </Typography>
            <Typography
                sx={{
                    fontSize: '1.5rem',
                    marginBottom: '2rem',
                    textAlign: 'center',
                }}
                variant="h5" color="textSecondary">
                Oops! The page you're looking for might be under construction or doesn't exist.
            </Typography>
            <Button
                variant="outlined"
                color="primary"
                component={Link}
                sx={{fontSize: '1rem', display: 'none'}}
                to="/"
            >
                Go Home
            </Button>
        </Container>
    );
};

export default NotFound;
