import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

export const NDRContext = createContext();

export function NDRContextProvider({ children }) {
    const { user, globalSearch, isSearch } = useContext(CommonContext);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allNDR, setAllNDR] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [uploadDataRes, setUploadDataRes] = useState();
    const [selected, setSelected] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [canceldialog, setCancelDialog] = useState(false);
    const [cancelRemarks, setCancelRemarks] = useState('');
    const [expandedTracking, setExpandedTracking] = useState(false);
    const [fStartDate, setFStartDate] = useState(null);
    const [fEndDate, setFEndDate] = useState(null);
    const [trackingInfo, setTrackingInfo] = useState([]);
    const [awbLr, setAwbLr] = useState({});
    const [osId, setOsId] = useState(1);
    const [filterType, setFilterType] = useState('order No.');
    const [searchValue, setSearchValue] = useState('');
    const [selectedCourierIds, setSelectedCourierIds] = useState([]);
    const [selectedMerchantIds, setSelectedMerchantIds] = useState([]);
    const [selectedOrderStatusIds, setSelectedOrderStatusIds] = useState([]);
    const [fDateRange, setFDateRange] = useState(['', '']);
    const [filterInput, setfilterInput] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [formDataTemp, setFormDataTemp] = useState({
        action: '',
        file: null,
    });
    const handlePanelOpen = () => {
        setIsPanelOpen(true);
    };

    const handlePanelClose = () => {
        setIsPanelOpen(false);
        setFormDataTemp({
            action: '',
            file: null,
        });
    };


    useEffect(() => {
        if (isSearch) {
            onLoadApi();
        }
    }, [isSearch])
    useEffect(() => {
        const fInput = {
            searchBy: filterType === 'LR No.' ? 'lrnum' : filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, ''),
            searchValue: searchValue ?? '',
            courier_id: selectedCourierIds ?? [],
            start_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
            type: osId !== 99 ? osId : '',
            ndr_status: selectedOrderStatusIds
        }

        setfilterInput(fInput)
    }, [searchValue, filterType, fDateRange, selectedCourierIds, selectedMerchantIds, selectedOrderStatusIds]);

    const onLoadApi = (dateRange, r) => {
        setPage(0);
        setRowsPerPage(20);
        setAllData([]);
        setAllNDR([]);
        // let postReq = { order_status_id: osId !== 99 ? osId : '', start_date: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : '', end_date: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : '' };
        let postReq = {
            searchBy: r === 'reset' || filterType === 'LR No.' ? 'lrnum' : filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, ''),
            searchValue: r === 'reset' ? '' : searchValue,
            courier_id: r === 'reset' ? [] : selectedCourierIds ?? [],
            start_date: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
            type: osId !== 99 ? osId : '',
            ndr_status: selectedOrderStatusIds
        };
        setfilterInput(postReq);
        // let postReq = filterInput;
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        } else {
            postReq.admin_id = user?.id
        }
        setFirstLoading(false);
        commonApi.post('/ndr/list', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllNDR(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onCancelOrder = () => {
        setIsLoading(true);
        commonApi.post('/orders/cancel-orders', { "order_ids": selected.join(', '), "remarks": cancelRemarks })
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('success');
                    setCancelRemarks('');
                    setSelected([]);
                    setCancelDialog(false);
                    onLoadApi();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setOpenAlert(true);
                setAlertMesaage(error.response?.data?.msg);
                setSeverity('error')
                console.error('Error fetching data:', error);
            });
    };
    const onLabelGen = () => {
        setIsLoading(true);
        commonApi.post('/orders/generate-address-labels', { "order_ids": selected.join(', ') })
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    setAlertMesaage('Address Label Generated');
                    setSeverity('success');
                    setSelected([]);
                    onLoadApi();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };
    const onExportOrder = () => {
        if (user?.user_type === "Merchant") {
            filterInput.merchant_id = user?.id
        } else {
            filterInput.admin_id = user?.id
        }
        setIsLoading(true);
        commonApi.post('ndr/export', filterInput)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };

    const handleCancelModal = () => {
        setCancelDialog(false);
        setCancelRemarks('');
        setSelected([]);
    };
    const handleAlertModalModal = () => {
        setAlertModal(true);
    };

    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };
    const handleCloseAlertModalModal = () => {
        setAlertModal(false);
        onLoadApi();
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    useEffect(() => {
        const defaultStartDate = moment().startOf('month');
        const defaultEndDate = moment().endOf('month');
        const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
        setFEndDate(moment(defaultEndDate.toDate()).format('YYYY-MM-DD'));
        setFStartDate(moment(defaultStartDate.toDate()).format('YYYY-MM-DD'));
        setFDateRange(defaultDateRange);
        onLoadApi(defaultDateRange);
        // const [fDateRange, setFDateRange] = useState(defaultDateRange);

    }, [osId]);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        formDataTemp,
        setFormDataTemp,
        isPanelOpen,
        setIsPanelOpen,
        handlePanelClose,
        handlePanelOpen,
        page,
        rowsPerPage,
        filterType,
        searchValue,
        selectedCourierIds,
        selectedMerchantIds,
        selectedOrderStatusIds,
        fDateRange,
        onExportOrder,
        filterInput,
        fStartDate,
        fEndDate,
        awbLr,
        osId,
        trackingInfo,
        expandedTracking,
        cancelRemarks,
        handleCancelModal,
        canceldialog,
        onCancelOrder,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        onLabelGen,
        selected,
        uploadDataRes,
        alertModal,
        handleCloseAlertModalModal,
        handleAlertModalModal,
        onLoadApi,
        isLoading,
        firstLoading,
        allNDR,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllNDR,
        setIsLoading,
        setFirstLoading,
        setAlertModal,
        setUploadDataRes,
        setSelected,
        setOpenAlert,
        setAlertMesaage,
        setSeverity,
        setCancelDialog,
        setCancelRemarks,
        setExpandedTracking,
        setTrackingInfo,
        setOsId,
        setAwbLr,
        setFEndDate,
        setFStartDate,
        setfilterInput,
        setFDateRange,
        setSelectedMerchantIds,
        setSelectedOrderStatusIds,
        setSelectedCourierIds,
        setSearchValue,
        setFilterType,
        setPage,
        setRowsPerPage,
    };

    // Render the provider with children
    return <NDRContext.Provider value={contextValue}>{children}</NDRContext.Provider>;
}
