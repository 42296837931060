import React, { useState, useContext } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CommonContext } from '../../context';

const AlertMessage = () => {
  const { severity, alertMesaage,  onCloseAlert } = useContext(CommonContext);
  const [open, setOpen] = useState(true);

  return (
    <Alert
      severity={severity} // 'error', 'warning', 'info', or 'success'
      variant="filled"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onCloseAlert}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{severity.charAt(0).toUpperCase() + severity.slice(1)}</AlertTitle>
      {alertMesaage}
    </Alert>
  );
};

export default AlertMessage;
