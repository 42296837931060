// customTheme.js

import { createTheme } from '@mui/material/styles';

const CustomTheme = createTheme({
  typography: {
    fontFamily: 'Rubik', // Replace 'Your Custom Font' with the actual font name or font stack you want to use
  },
});

export default CustomTheme;
