import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export const ScanContext = createContext();

export function ScanContextProvider({ children }) {
    const { user } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [viewData, setViewData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [allScan, setAllScan] = useState([]);
    const [firstLoading, setFirstLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [filterInput, setfilterInput] = useState({});
    const [filterType, setFilterType] = useState('awb no');
    const [searchValue, setSearchValue] = useState('');
    const today = moment();
    const defaultStartDate = today.clone().startOf('month');
    const defaultEndDate = today.clone().endOf('month');
    const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
    const [fDateRange, setFDateRange] = useState(defaultDateRange);
    const navigate = useNavigate();
    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setViewData('');
        setAddModal(false);
    };

    const onLoadApi = () => {
        // let postReq = {};
        if (user?.user_type === "Merchant") {
            filterInput.merchant_id = user?.id
        } else {
            filterInput.admin_id = user?.id
        }
        setFirstLoading(false);
        commonApi.post('/orders/scanlist', filterInput)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllScan(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        const fInput = {
            searchBy: searchValue ? filterType === 'event name' ? 'event_name' : filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, '') : '',
            searchValue: searchValue ?? '',
            start_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : '',
            end_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : '',
        }

        setfilterInput(fInput)
    }, [searchValue, filterType, fDateRange]);
    useEffect(() => {
        onLoadApi();
    }, []);
    const onExport = () => {
        if (user?.user_type !== "Merchant") {
            filterInput.admin_id = user?.id
        }
        setIsLoading(true);
        commonApi.post('/orders/scanlist/export', filterInput)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };
    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        fDateRange,
        setFDateRange,
        onExport,
        searchValue,
        setSearchValue,
        filterType,
        setFilterType,
        setfilterInput,
        filterInput,
        setfilterInput,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        onLoadApi,
        firstLoading,
        isLoading,
        viewData,
        allScan,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllScan,
        setViewData,
        setIsLoading,
        setFirstLoading,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
    };

    // Render the provider with children
    return <ScanContext.Provider value={contextValue}>{children}</ScanContext.Provider>;
}
